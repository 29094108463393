import { NetworkInfo, PolygonNetworkInfo } from 'constants/networks'

export function networkPrefix(activeNewtork: NetworkInfo) {
  const isPolygon = activeNewtork === PolygonNetworkInfo
  if (isPolygon) {
    return '/'
  }
  const prefix = '/' + activeNewtork.route.toLocaleLowerCase() + '/'
  return prefix
}
